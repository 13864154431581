var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { get, getpath, post, upload } from '@/utils/http';
const getPayoutChannel = (data) => get('/setting/getPayoutChannel', data);
const updatePayoutChannel = (data) => {
    const { authenticateCode } = data, params = __rest(data, ["authenticateCode"]);
    let url = '/setting/updatePayoutChannel';
    if (authenticateCode) {
        url += `?authenticateCode=${authenticateCode}`;
    }
    return post(url, params);
};
const getSinglePayoutChannel = (id, authenticationCode) => getpath('/setting/payoutChannel/' + id + (authenticationCode ? `?authenticateCode=${authenticationCode}` : ''));
const getAllPayoutChannel = () => get('/setting/getAllPayoutChannels/');
const createPayoutOrder = (data, authenticateCode) => {
    let url = '/payout/order/create';
    if (authenticateCode) {
        url += `?authenticateCode=${authenticateCode}`;
    }
    return post(url, data);
};
const getPayoutOrder = (data) => post('/payout/order/list', data);
const exportPayoutOrder = (data) => post('/payout/order/export', data);
const getSinglePayoutOrder = (id) => get('/payout/order/' + id);
const manualAuditPayoutOrder = (data) => post('/payout/order/manualAudit', data);
const getSinglePayoutOrderLog = (orderNo) => get('/payout/order/log/' + orderNo);
const createRegularReceiverAccount = (data) => post('/payout/regular/receiverAccount/update', data);
const updateRegularReceiverAccount = (data) => post('/payout/regular/receiverAccount/update', data);
const getRegularReceiverAccount = (data) => get('/payout/regular/receiverAccount/list', data);
const getSingleRegularReceiverAccount = (id) => get('/payout/regular/receiverAccount/' + id);
const deleteRegularReceiverAccount = (id) => post('/payout/regular/receiverAccount/delete/' + id);
const getPayoutOrderBalance = (data) => post(`/payout/invoke/${data.channel_code}/getBalance`, {
    channel_id: data.channel_id,
    currency: data.currency
});
const getWowspayBalance = (data) => post('/payout/invoke/WowsPayAdapter/getBalance', data);
const getPoHelp2PayAdapterBalance = (data) => post('/payout/invoke/PoHelp2PayAdapter/getBalance', data);
const getGateExpressBalance = (data) => post('/payout/invoke/gateExpressAdapter/getBalance', data);
const getPOChippayAdapterBalance = (data) => post('/payout/invoke/POChippayAdapter/getBalance', data);
const getPOMyPayAdapterBalance = (data) => post('/payout/invoke/POMyPayAdapter/getBalance', data);
const getPOCoinQPayAdapterBalance = (data) => post('/payout/invoke/POCoinQPayAdapter/getBalance', data);
const getPOEchelonPayAdapterBalance = (data) => post('/payout/invoke/POEchelonPayAdapter/getBalance', data);
const getPayoutOrderLimit = (data) => post('/payout/invoke/epayAdapterJPY/getLimit', data);
const getPagsmileBalance = (data) => post('/payout/invoke/POPagsmileAdapter/getBalance', data);
const getPayoutBankList = (data) => post('/payout/invoke/epayAdapterJPY/getBankList', data);
const getPayoutCalculateAmount = (data) => post('/payout/invoke/epayAdapterJPY/calculateAmount', data);
const getPayoutPurposeOfRemittanceList = (data) => post('/payout/invoke/epayAdapterJPY/getPurposeOfRemittanceList', data);
const getPayoutSourceOfFundList = (data) => post('/payout/invoke/epayAdapterJPY/getSourceOfFund', data);
const getAllPayoutOrderChannel = () => get('/setting/payout/channel/list');
const payoutChannelChecking = (channelCode) => post(`/payout/check/invoke/${channelCode}`);
const getPayoutBankListForWowsPay = (data) => post('/payout/invoke/WowsPayAdapter/getBankList', data);
const getPayoutBankListFor12Pay = (data) => post('/payout/invoke/PO12PAYAdapter/getBankList', data);
const getPayoutBankListForPagsmile = (data) => post('/payout/invoke/POPagsmileAdapter/getBankList', data);
const getPayoutBankListForHelp2Pay = (data) => post('/payout/invoke/PoHelp2PayAdapter/getBankList', data);
const getPayoutAccountTypeForPagsmile = (data) => post('/payout/invoke/POPagsmileAdapter/getAccountTypeList', data);
const getPayoutDocumentTypeForPagsmile = (data) => post('/payout/invoke/POPagsmileAdapter/getDocumentTypeList', data);
const getPayoutRegionForPagsmile = (data) => post('/payout/invoke/POPagsmileAdapter/getRegionList', data);
const getPayoutBankListForEchelonPay = (data) => post('/payout/invoke/POEchelonPayAdapter/getBankList', data);
const uploadBatchPayoutExcel = (data) => upload('/payout/channel/uploadBatchPayoutExcel', data);
const getPayoutBankListForPaymentAsia = (data) => post('/payout/invoke/POPaymentAsiaAdapter/getBankList', data);
const getWebhook = () => get('/payout/webhook/info');
const saveWebhook = (data) => post('/payout/webhook/save', data);
const payoutOrderCallback = (order_no) => post('/payout/order/callback', { order_no });
const payoutOrderBatch = (data) => post('/payout/order/batch', data);
const payoutOrderOperate = (data) => post('/payout/order/operate', data);
const payoutOrderCancel = (data) => post('/payout/order/cancel', data);
const payoutOrderReverse = (data) => post('/payout/order/unsettlement', data);
const payoutOrderSplit = (data) => post('/payout/order/split', data);
const payoutOrderCount = (data) => post('/payout/order/count', data);
const getFormParamOptions = (url, data) => post(url, data);
export default {
    exportPayoutOrder,
    getPayoutOrder,
    getAllPayoutChannel,
    getSinglePayoutChannel,
    getPayoutChannel,
    updatePayoutChannel,
    getRegularReceiverAccount,
    createRegularReceiverAccount,
    updateRegularReceiverAccount,
    deleteRegularReceiverAccount,
    createPayoutOrder,
    getSingleRegularReceiverAccount,
    getSinglePayoutOrderLog,
    getSinglePayoutOrder,
    manualAuditPayoutOrder,
    getPayoutOrderBalance,
    getPayoutOrderLimit,
    getPayoutBankList,
    getPayoutCalculateAmount,
    getPayoutPurposeOfRemittanceList,
    getPayoutSourceOfFundList,
    getAllPayoutOrderChannel,
    payoutChannelChecking,
    getWowspayBalance,
    getGateExpressBalance,
    getPagsmileBalance,
    getPayoutBankListForWowsPay,
    getPayoutBankListFor12Pay,
    getPayoutBankListForHelp2Pay,
    getPoHelp2PayAdapterBalance,
    getPayoutBankListForPagsmile,
    getPayoutAccountTypeForPagsmile,
    getPayoutDocumentTypeForPagsmile,
    getPayoutRegionForPagsmile,
    getPayoutBankListForPaymentAsia,
    uploadBatchPayoutExcel,
    getPOMyPayAdapterBalance,
    getPOChippayAdapterBalance,
    getWebhook,
    saveWebhook,
    payoutOrderCallback,
    payoutOrderBatch,
    payoutOrderOperate,
    payoutOrderCancel,
    payoutOrderReverse,
    payoutOrderSplit,
    payoutOrderCount,
    getPOCoinQPayAdapterBalance,
    getPayoutBankListForEchelonPay,
    getPOEchelonPayAdapterBalance,
    getFormParamOptions
};
